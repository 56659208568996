import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useCurrentProjectKey } from 'context/current-project';
import { useProjectsProviders } from 'context/projects-providers';
import { useAvailableProjectKeys } from 'hooks/use-available-project-keys';
import { DATA_PROVIDERS, MIN_SEARCH_SYMBOLS_LENGTH, SPORT_TYPES } from 'utils/constants';
import {
  deserializeSportTypes,
  deserializeSports,
  deserializeCategories,
  deserializeEvents,
  deserializeTournaments,
  deserializeEventCountries,
  deserializeCountries,
  deserializeCurrencies,
  deserializeProviders,
  deserializeAffilkaTags,
  deserializePlayersTags,
  deserializeTurfsportCategories,
  deserializeTurfSportSports,
  deserializeTurfsportTournaments,
  deserializeTurfsportEvents,
} from 'utils/deserialize';
import { pipe } from 'utils/fp.utils';
import { createQueryKeys } from 'utils/query';
import { sortBy } from 'utils/sort';

import { apiClient } from './client-sportsbook-api';

const MAP_DATA_PROVIDER = {
  [DATA_PROVIDERS.SPORTSBOOK]: {
    deserializeCategories,
    deserializeSports,
    deserializeTournaments,
    deserializeEvents,
  },
  [DATA_PROVIDERS.TURFSPORT]: {
    prefix: 'virtual-wallet/',
    deserializeCategories: deserializeTurfsportCategories,
    deserializeSports: deserializeTurfSportSports,
    deserializeTournaments: deserializeTurfsportTournaments,
    deserializeEvents: deserializeTurfsportEvents,
  },
};

const dictionaryQueriesKeys = createQueryKeys('dictionary');

export function useDictionary({
  dictionaryKey,
  data,
  deserialize,
  dataProvider = DATA_PROVIDERS.SPORTSBOOK,
  params,
  config = {},
}) {
  const projectKey = useCurrentProjectKey();

  return useQuery(
    dictionaryQueriesKeys.list({ dictionaryKey, projectKey, data, ...params }),
    () =>
      apiClient.getDictionary({
        dictionaryKey: `${MAP_DATA_PROVIDER[dataProvider]?.prefix ?? ''}${dictionaryKey}`,
        deserialize,
        projectKey,
        params,
        data,
      }),
    {
      initialData: [],
      ...config,
      enabled: Boolean(projectKey) && (config.enabled ?? true),
    }
  );
}

export function useDictionarySearch({ params = {}, config = {} } = {}) {
  const { type, status, term } = params;

  const projectKey = useCurrentProjectKey();
  const deserialize = {
    events: deserializeEvents,
    tournaments: deserializeTournaments,
  }[type];

  return useQuery(
    ['dictionary', 'search', params],
    () =>
      apiClient.makeRequest(`/dictionary/search/${type}`, {
        headers: { 'X-Project': projectKey },
        params: { term, status },
      }),
    {
      initialData: [],
      select: deserialize,
      ...config,
      enabled:
        (config.enabled ?? true) &&
        Boolean(projectKey) &&
        term?.length >= MIN_SEARCH_SYMBOLS_LENGTH,
    }
  );
}

export function useCategories({ params = {}, config = {} } = {}) {
  const {
    sportIds,
    sportExternalIds,
    withRequiredProvider = true,
    provider: dataProvider = DATA_PROVIDERS.SPORTSBOOK,
  } = params;
  const providers = useProjectsProviders();

  const providersToUse =
    dataProvider === DATA_PROVIDERS.TURFSPORT ? DATA_PROVIDERS.TURFSPORT : providers;

  return useDictionary({
    params: {
      sport_id: sportIds,
      sport_external_id: sportExternalIds,
      provider: withRequiredProvider ? providersToUse : undefined,
    },
    dataProvider,
    dictionaryKey: 'categories',
    config: {
      select: MAP_DATA_PROVIDER[dataProvider].deserializeCategories,
      ...config,
      enabled:
        (sportExternalIds?.length > 0 || sportIds?.length > 0) &&
        (withRequiredProvider ? providers?.length > 0 : true) &&
        (config.enabled ?? true),
    },
  });
}

export function useEventsDictionary({ params = {}, config = {} } = {}) {
  const {
    eventIds,
    tournamentIds,
    tournamentsExternalIds,
    externalIds,
    status,
    withRequiredProvider = true,
    provider: dataProvider = DATA_PROVIDERS.SPORTSBOOK,
  } = params;
  const providers = useProjectsProviders();

  const providersToUse =
    dataProvider === DATA_PROVIDERS.TURFSPORT ? DATA_PROVIDERS.TURFSPORT : providers;

  return useDictionary({
    params: {
      id: eventIds,
      tournament_id: tournamentIds,
      tournament_external_id: tournamentsExternalIds,
      external_id: externalIds,
      provider: withRequiredProvider ? providersToUse : undefined,
      status,
    },
    dictionaryKey: 'events',
    dataProvider,
    config: {
      select: MAP_DATA_PROVIDER[dataProvider].deserializeEvents,
      ...config,
      enabled:
        (externalIds?.length > 0 ||
          tournamentIds?.length > 0 ||
          tournamentsExternalIds?.length > 0) &&
        (withRequiredProvider ? providers?.length > 0 : true) &&
        (config.enabled ?? true),
    },
  });
}

export function useSports({ params = {}, config = {} } = {}) {
  const {
    sportId,
    sportType = [SPORT_TYPES.REGULAR, SPORT_TYPES.CYBER],
    withRequiredProvider = true,
    provider: dataProvider = DATA_PROVIDERS.SPORTSBOOK,
  } = params;
  const providers = useProjectsProviders();

  const providersToUse =
    dataProvider === DATA_PROVIDERS.TURFSPORT ? DATA_PROVIDERS.TURFSPORT : providers;

  return useDictionary({
    params: {
      id: sportId,
      sport_type: sportType,
      provider: withRequiredProvider ? providersToUse : undefined,
    },
    dataProvider,
    dictionaryKey: 'sports',
    config: {
      select: MAP_DATA_PROVIDER[dataProvider].deserializeSports,
      ...config,
      enabled:
        (sportId?.length > 0 || sportType?.length > 0) &&
        (withRequiredProvider ? providers?.length > 0 : true) &&
        (config.enabled ?? true),
    },
  });
}

export function useSportTypes({ sportType, withRequiredProvider = true, config = {} } = {}) {
  const providers = useProjectsProviders();

  return useDictionary({
    params: {
      sport_type: sportType,
      provider: withRequiredProvider ? providers : undefined,
    },
    dictionaryKey: 'sport-types',
    config: {
      select: deserializeSportTypes,
      enabled: withRequiredProvider ? providers?.length > 0 : true,
      ...config,
    },
  });
}

export function useTournaments({ params = {}, config = {} } = {}) {
  const {
    categoryIds,
    categoryExternalIds,
    tournamentExternalIds,
    tournamentIds,
    withRequiredProvider = true,
    provider: dataProvider = DATA_PROVIDERS.SPORTSBOOK,
    onlyTop = undefined,
  } = params;
  const providers = useProjectsProviders();

  const providersToUse =
    dataProvider === DATA_PROVIDERS.TURFSPORT ? DATA_PROVIDERS.TURFSPORT : providers;

  return useDictionary({
    params: {
      is_top: onlyTop,
      id: tournamentIds,
      category_id: categoryIds,
      category_external_id: categoryExternalIds,
      external_id: tournamentExternalIds,
      provider: withRequiredProvider ? providersToUse : undefined,
    },
    dictionaryKey: 'tournaments',
    dataProvider,
    config: {
      select: MAP_DATA_PROVIDER[dataProvider].deserializeTournaments,
      enabled:
        (withRequiredProvider ? providers?.length > 0 : true) &&
        (categoryIds?.length > 0 ||
          categoryExternalIds?.length > 0 ||
          tournamentExternalIds?.length > 0),
      ...config,
    },
  });
}

function translateCountries(locale) {
  const intl = new Intl.DisplayNames([locale], { type: 'region' });
  return countries => countries.map(country => ({ ...country, name: intl.of(country.cca2) }));
}

function transformCountries(locale) {
  return pipe(sortBy('name'), translateCountries(locale));
}

export function useEventCountries({ config = {} } = {}) {
  const { locale } = useIntl();

  return useDictionary({
    dictionaryKey: 'event-countries',
    deserialize: deserializeEventCountries,
    config: {
      select: transformCountries(locale),
      ...config,
    },
  });
}

const getFlatTags = data => data?.pages?.flatMap(page => page.data) ?? [];

export function usePlayersTags({ params = {}, config = {} } = {}) {
  const projectKey = useCurrentProjectKey();
  const dictionaryKey = 'players-tags';

  const { projectKeys: availableProjectKeys } = useAvailableProjectKeys(params.projectKeys);

  return useInfiniteQuery({
    queryKey: dictionaryQueriesKeys.list({
      ...params,
      dictionaryKey,
      projectKeys: availableProjectKeys,
    }),
    queryFn: ({ pageParam }) =>
      apiClient.getDictionary({
        projectKey,
        dictionaryKey,
        deserialize: deserializePlayersTags,
        params: {
          ids: params.ids,
          projects: availableProjectKeys,
          page: pageParam?.page ?? 1,
          limit: params.limit ?? 25,
          term: params.term,
        },
        baseURL: apiClient.baseUrlApiV2,
      }),
    placeholderData: [],
    ...config,
    select: config?.select ? pipe(getFlatTags, config.select) : getFlatTags,
    getNextPageParam: lastData => {
      if (lastData?.pagination?.page === lastData?.pagination?.lastPage) return null;

      return { page: (lastData?.pagination?.page ?? 0) + 1 };
    },
    enabled: availableProjectKeys?.length > 0,
  });
}

export function useCountries({ config = {} } = {}) {
  const { locale } = useIntl();

  return useDictionary({
    dictionaryKey: 'countries',
    deserialize: deserializeCountries,
    config: { select: transformCountries(locale), ...config },
  });
}

export function useCurrencies({ config = {} } = {}) {
  return useDictionary({
    dictionaryKey: 'currencies',
    deserialize: deserializeCurrencies,
    config,
  });
}

export function useCurrencyInfo({ params = {}, config = {} } = {}) {
  const { code } = params;

  const selectCurrency = useCallback(
    data => data?.find(currency => currency.code === code),
    [code]
  );

  return useCurrencies({
    config: {
      cacheTime: Infinity,
      staleTime: Infinity,
      initialData: undefined,
      select: selectCurrency,
      ...config,
    },
  });
}

export function useProviders({ config = {} } = {}) {
  return useDictionary({
    dictionaryKey: 'event-providers',
    deserialize: deserializeProviders,
    config,
  });
}

export function useVideoProviders({ config = {} } = {}) {
  return useDictionary({
    dictionaryKey: 'video-providers',
    config,
  });
}

export function useSTags({ params = {}, config = {} } = {}) {
  const projectKey = useCurrentProjectKey();
  const dictionaryKey = 'affiliate-tags';

  const { projectKeys: paramsProjectKeys, ...restParams } = params;
  const { projectKeys } = useAvailableProjectKeys(paramsProjectKeys);

  return useInfiniteQuery({
    queryKey: dictionaryQueriesKeys.list({
      dictionaryKey,
      projectKey,
      projectKeys,
      ...restParams,
    }),
    queryFn: ({ pageParam }) =>
      apiClient.getDictionary({
        projectKey,
        dictionaryKey,
        deserialize: deserializeAffilkaTags,
        params: {
          page: pageParam?.page ?? 1,
          limit: 25,
          term: params.term,
          projects: projectKeys,
        },
        baseURL: apiClient.baseUrlApiV2,
      }),
    ...config,
    getNextPageParam: lastData => {
      if (lastData?.pagination?.page === lastData?.pagination?.lastPage) return null;

      return { page: (lastData?.pagination?.page ?? 0) + 1 };
    },
  });
}

export function useMergedByTypes({ config = {} } = {}) {
  return useDictionary({
    dictionaryKey: 'players-hub/merge-types',
    config,
  });
}
